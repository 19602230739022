var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(Object.entries(_vm.data).length === 0 ? _vm.$t('Add New') : _vm.$t('Update'))+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"formData"},[_c('b-form',{staticClass:"p-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Name'),"label-for":"register-name"}},[_c('validation-provider',{attrs:{"name":"name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-name","name":"register-name","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Name')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Detail'),"label-for":"register-detail"}},[_c('validation-provider',{attrs:{"name":"detail","vid":"detail","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-detail","name":"register-detail","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Detail')},model:{value:(_vm.detail),callback:function ($$v) {_vm.detail=$$v},expression:"detail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Max Weight'),"label-for":"maxWeight"}},[_c('validation-provider',{attrs:{"name":"MaxWeight","vid":"maxWeight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"maxWeight","raw":false,"options":_vm.optionCleave.number,"placeholder":_vm.$t('Max Weight')},model:{value:(_vm.maxWeight),callback:function ($$v) {_vm.maxWeight=$$v},expression:"maxWeight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Price'),"label-for":"price"}},[_c('validation-provider',{attrs:{"name":"Price","vid":"price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"price","raw":false,"options":_vm.optionCleave.number,"placeholder":_vm.$t('Price')},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Size'),"label-for":"size"}})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Width'),"label-for":"sizeWidth"}},[_c('validation-provider',{attrs:{"name":"sizeWidth","vid":"sizeWidth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"sizeWidth","raw":false,"options":_vm.optionCleave.number,"placeholder":_vm.$t('Width')},model:{value:(_vm.sizeWidth),callback:function ($$v) {_vm.sizeWidth=$$v},expression:"sizeWidth"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Length'),"label-for":"sizeLength"}},[_c('validation-provider',{attrs:{"name":"sizeLength","vid":"sizeLength","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"sizeLength","raw":false,"options":_vm.optionCleave.number,"placeholder":_vm.$t('Length')},model:{value:(_vm.sizeLength),callback:function ($$v) {_vm.sizeLength=$$v},expression:"sizeLength"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Height'),"label-for":"sizeHeight"}},[_c('validation-provider',{attrs:{"name":"sizeHeight","vid":"sizeHeight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"sizeHeight","raw":false,"options":_vm.optionCleave.number,"placeholder":_vm.$t('Height')},model:{value:(_vm.sizeHeight),callback:function ($$v) {_vm.sizeHeight=$$v},expression:"sizeHeight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.validationForm()}}},[_vm._v(" "+_vm._s(_vm.$t('Submit'))+" ")]),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }