<template>
  <b-sidebar id="add-new-user-sidebar" :visible="isSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow
    backdrop no-header right @change="val => $emit('update:is-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t('Add New') : $t('Update') }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col cols="12">
              <!-- Detail -->
              <b-form-group :label="$t('Name')" label-for="register-name">
                <validation-provider #default="{ errors }" name="name" vid="name" rules="required">
                  <b-form-input id="register-name" v-model="name" name="register-name"
                    :state="errors.length > 0 ? false : null" :placeholder="$t('Name')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <!-- Detail -->
              <b-form-group :label="$t('Detail')" label-for="register-detail">
                <validation-provider #default="{ errors }" name="detail" vid="detail" rules="required">
                  <b-form-input id="register-detail" v-model="detail" name="register-detail"
                    :state="errors.length > 0 ? false : null" :placeholder="$t('Detail')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="$t('Max Weight')" label-for="maxWeight">
                <validation-provider #default="{ errors }" name="MaxWeight" vid="maxWeight" rules="required">
                  <cleave id="maxWeight" v-model="maxWeight" class="form-control" :raw="false"
                    :options="optionCleave.number" :placeholder="$t('Max Weight')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="$t('Price')" label-for="price">
                <validation-provider #default="{ errors }" name="Price" vid="price" rules="required">
                  <cleave id="price" v-model="price" class="form-control" :raw="false" :options="optionCleave.number"
                    :placeholder="$t('Price')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="$t('Size')" label-for="size">

              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group :label="$t('Width')" label-for="sizeWidth">
                <validation-provider #default="{ errors }" name="sizeWidth" vid="sizeWidth" rules="required">
                  <cleave id="sizeWidth" v-model="sizeWidth" class="form-control" :raw="false"
                    :options="optionCleave.number" :placeholder="$t('Width')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group :label="$t('Length')" label-for="sizeLength">
                <validation-provider #default="{ errors }" name="sizeLength" vid="sizeLength" rules="required">
                  <cleave id="sizeLength" v-model="sizeLength" class="form-control" :raw="false"
                    :options="optionCleave.number" :placeholder="$t('Length')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group :label="$t('Height')" label-for="sizeHeight">
                <validation-provider #default="{ errors }" name="sizeHeight" vid="sizeHeight" rules="required">
                  <cleave id="sizeHeight" v-model="sizeHeight" class="form-control" :raw="false"
                    :options="optionCleave.number" :placeholder="$t('Height')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" @click="validationForm()">
              {{ $t('Submit') }}
            </b-button>
            <b-button type="button" variant="outline-secondary" @click="hide">
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar,
  BButton,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCardText,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BForm,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import store from '@/store'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BSidebar,
    BButton,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BCardText,
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    flatPickr,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    Cleave,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => { },
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      dataId: null,
      name: '',
      detail: '',
      maxWeight: 0,
      price: 0,
      sizeWidth: 0,
      sizeLength: 0,
      sizeHeight: 0,
      status: true,
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            _id, name, detail, maxWeight, price, sizeWidth, sizeLength, sizeHeight, status,
          } = this.data
          this.dataId = _id
          this.name = name
          this.detail = detail
          this.maxWeight = maxWeight
          this.price = price
          this.sizeWidth = sizeWidth
          this.sizeLength = sizeLength
          this.sizeHeight = sizeHeight
          this.status = status
        }
      }
    },
  },
  methods: {
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t(
                'or',
              )} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.image = ''
    },
    initValues() {
      this.dataId = null
      this.name = ''
      this.detail = ''
      this.maxWeight = 0
      this.price = 0
      this.sizeWidth = 0
      this.sizeLength = 0
      this.sizeHeight = 0
      this.status = true
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            name: this.name,
            detail: this.detail,
            maxWeight: this.maxWeight.toString().replaceAll(',', ''),
            price: this.price.toString().replaceAll(',', ''),
            sizeWidth: this.sizeWidth.toString().replaceAll(',', ''),
            sizeLength: this.sizeLength.toString().replaceAll(',', ''),
            sizeHeight: this.sizeHeight.toString().replaceAll(',', ''),
            status: this.status,
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/update`, obj)
              // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)

                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          } else {
            delete obj.id
            store
              .dispatch(`${this.storeModuleName}/add`, obj)
              // eslint-disable-next-line no-unused-vars
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)

                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
